/* Provide sufficient contrast against white background */
@font-face {
  font-family: 'Lato';
  font-weight: 100;
  src: local('Lato'), url(./assets/fonts/Lato-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 300;
  src: local('Lato'), url(./assets/fonts/Lato-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 400;
  src: local('Lato'), url(./assets/fonts/Lato-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-weight: 700;
  src: local('Lato'), url(./assets/fonts/Lato-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 900;
  src: local('Lato'), url(./assets/fonts/Lato-Black.ttf) format('truetype');
}

*{
  font-family: Lato, Arial, sans-serif;

}

.app-main {
  background-color: #f8f9fa;
  min-height: 100vh;
  height: 100%;
  padding-bottom: 36px;
}

a {
  color: #0366d6;
}

ol > li {
padding: 10px 0;
}

code {
  color: #E01A76;
}

h1 {
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
}

h3 {
  margin: 24px 0;
  padding: 12px 0;
  text-transform: uppercase;
}

h4 {
  text-align: center;
  padding: 24px 0 15px 0;
}

h5 {
  font-weight: 600;
  padding: 24px 0 10px 0;
  text-transform: uppercase;

}

h5.modal-title {
  font-weight: 500;
  padding: 0px;
  text-transform: none;
}

h6 {margin-top: 20px;}


.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.btn-secondary {
  background-color: #403F6F;
  border-color: #403F6F;
}
.btn-secondary:hover {
  background-color: #2e2e54;
  border-color: #2e2e54;
}

.icon-contact {
  margin-right: 10px;
}

.btn-arrow {
  height: 30px;
  width: 30px;
  color: #403F6F;
  cursor: pointer;
}

.btn-arrow:hover {
  color: #2e2e54;
}
.btn-icon{
  height: 100%;
  width: 20px;
  margin-right: 10px;
}

.table-icon {
  padding-top: 5px;
  color: #403F6F;
  height: 100%;
  width: 25px;
  cursor: pointer;
}

.table-icon:hover {
  color: #2e2e54;
}


.link {
  color:#403F6F;
  text-decoration: none;
  font-weight: 500;
  border-width: 0;
}
.link:hover {
  color: #161635;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}
