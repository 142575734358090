a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.navbar-logo {
  height: 100px
}

.dropdown-item:active {
  background-color: #adb5bd;
}

.nav-text {
  /* text-transform: uppercase; */
  font-size: 16px;
  font-weight: 500;
}

.nav-text:hover {
  background-color: #adb5bd1a;
  cursor: pointer;
}
